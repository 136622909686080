<template>

  <div v-if="edited_week">   

    <WeekEditFazaHarSmoke 
      v-if="edited_week.faza == 2" 
      :week="edited_week" 
      :diary="diary" 
      @updateweek="emits('updateweek')" 
      @saveweek="saveWeek($event)"
      @savesmoke="saveSmoke($event)"
      @canceleditweek="emits('canceleditweek', $event)" 
      />    
 

  </div>
</template>

<script setup>


const route = useRoute();
const { $api, $ga, $helper, $head, $popup, $vartpl } = useNuxtApp();
const { t } = useI18n();
const link = ref(route.params['week']);
const edited_week = ref(null);
const emits = defineEmits(['saveWeek', 'updateweek', 'canceleditweek']);

const props = defineProps({
  week: {
    type: Object,  
  },
  diary: {
    type: Object,            
    required: true,
  }
});


const prepareData = () => {

  console.log(edited_week);

  if(isNaN(link.value)){       

    edited_week.value = JSON.parse(JSON.stringify($vartpl.week));

    if(link.value.substr(4,1) == 'g'){
      edited_week.value.faza = -1;     
      edited_week.value.item_germination.items_seed = props.diary.items_equip.filter(e => e.category == 'seed');           
    }else if(link.value.substr(4,1) == 'v'){
    console.log('veg');
      edited_week.value.faza = 0;        
    }else if(link.value.substr(4,1) == 'f'){
      edited_week.value.faza = 1;        
    }else if(link.value.substr(4,1) == 'h'){
      edited_week.value.faza = 2;        
    }
    console.log('prepare');
    console.log(link.value.substr(4,1));
    console.log(edited_week.value.faza);
    
  }else{ 

    edited_week.value = JSON.parse(JSON.stringify(props.week)); 
  }

  // console.log('edited_week.value');
  // console.log(edited_week.value);
}


const changeFazaVeg = (vl) => {
  edited_week.value = vl; 
  edited_week.value.item_flowering = vl.item_vegetation; 
  edited_week.value.item_vegetation = null; 
  edited_week.value.faza = 1; 
}

const changeFazaFlo = (vl) => {
  edited_week.value = vl; 
  edited_week.value.item_vegetation = vl.item_flowering; 
  edited_week.value.item_flowering = null; 
  edited_week.value.faza = 0; 
}


const validateWeek = (edited_week) => {  
  if(edited_week.faza == '-1'){

  }else if(edited_week.faza == '2' && edited_week.is_smoke == 1){
    if(!edited_week.items_photo?.length){
      $popup.error('Empty photos');
      return false;
    }
  }else{

    if(!edited_week.days){
      $popup.error('Change week number');
      return false;
    }
    if(!edited_week.items_photo?.length){
      $popup.error('Empty photos');
      return false;
    }
    if(!edited_week.text){
      $popup.error('Empty comment field');
      return false;
    }
  }
  return true;
} 

// const saveSmoke = (edited_week) => {   
//   edited_week.is_smoke = 1;
//   console.log(edited_week)
//   saveWeek(edited_week);
// }

const saveWeek = (edited_week) => {   
  if(!validateWeek(edited_week)) 
    return false;         
  var form = edited_week;              
  if(!form.id){   
    $api.postWeek(props.diary.id, form) 
    .then((res) => {
      emits('updateweek', res.data.value.data.id);              
    })
    .catch(function (error) { 
      // $popup.error(t('create_week_view_error_failed'));
      $popup.error('Error');
    });  
  }else{
    $api.putWeek(props.diary.id, form.id, form) 
    .then((res) => {      
      console.log('saved week id');      
      console.log(res.data.value.data);      
      emits('updateweek', res.data.value.data.id);    
    })
    .catch(function (error) { 
      // $popup.error(t('create_week_view_error_failed'));    
      $popup.error('Error');    
    });
  }
}




onMounted(() => {  
  prepareData();
});


onUnmounted(() => {  
  edited_week.value = null;
});




</script>

<style scoped>
 
</style>
